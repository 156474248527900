import { Dispatch, SetStateAction, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { SettingsForm } from './SettingsModalForm';
import { SettingsModalMain } from './SettingsModalMain';
import { UserPreferences, columnsDefault, widgetsDefault } from '../../../store/User';
import { updateForm } from '../../../store/BulkForm';
import { Account } from '../../../types';
import { SettingsModalSubAccountsForm } from './SettingsModalSubAccountsForm';

type ModalView = 'main' | 'column' | 'widget' | 'subAccounts';
export interface SettingsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userPreferences: UserPreferences;
  userName: string | undefined;
  id: string;
  secondaryAccounts?: Account[];
}

export const SettingsModal = ({
  open,
  setOpen,
  userPreferences,
  userName,
  id,
  secondaryAccounts
}: SettingsModalProps) => {
  const [modalViewState, setModalViewState] = useState<Record<ModalView, boolean>>({
    main: true,
    column: false,
    widget: false,
    subAccounts: false
  });
  const [newUserPreferences, setNewUserPreferences] = useState<UserPreferences>(userPreferences);
  const [selectedSecondaryAccounts, setSelectedSecondaryAccounts] = useState<Account[]>([]);

  const handleSave = () => {
    updateForm(id, { userCustomizations: newUserPreferences, accounts: selectedSecondaryAccounts }, 'user');
    navigateModal('main');
    setOpen(false);
  };

  const handleSecondaryAccountChange = (accts: Account[] | null) => {
    setSelectedSecondaryAccounts(accts || []);
  };

  const navigateModal = (view: ModalView) => {
    switch (view) {
      case 'column':
        return setModalViewState({ main: false, widget: false, column: true, subAccounts: false });
      case 'widget':
        return setModalViewState({ main: false, widget: true, column: false, subAccounts: false });
      case 'subAccounts':
        return setModalViewState({ main: false, widget: false, column: false, subAccounts: true });
      case 'main':
      default:
        return setModalViewState({ main: true, widget: false, column: false, subAccounts: false });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}>
      {modalViewState.column && (
        <SettingsForm
          setNewUserPreferences={setNewUserPreferences}
          customizations={newUserPreferences}
          title="Inventory"
          onBack={() => navigateModal('main')}
          onSave={() => navigateModal('main')}
        />
      )}
      {modalViewState.widget && (
        <SettingsForm
          customizations={newUserPreferences}
          setNewUserPreferences={setNewUserPreferences}
          title="Widgets"
          onBack={() => navigateModal('main')}
          onSave={() => navigateModal('main')}
        />
      )}
      {modalViewState.subAccounts && secondaryAccounts !== undefined && (
        <SettingsModalSubAccountsForm
          accounts={secondaryAccounts}
          selectedAccounts={selectedSecondaryAccounts}
          handleAccountSelect={handleSecondaryAccountChange}
          onBack={() => navigateModal('main')}
          onSave={() => navigateModal('main')}
        />
      )}
      {modalViewState.main && (
        <SettingsModalMain
          username={userName || ''}
          columnsCustom={!columnsDefault(newUserPreferences.content.inventory_grid_All.muiConfig)}
          widgetsCustom={!widgetsDefault(newUserPreferences.content.widgets)}
          subAccountsCustom={!!selectedSecondaryAccounts.length}
          subAccounts={secondaryAccounts}
          onEditColumns={() => navigateModal('column')}
          onEditWidgets={() => navigateModal('widget')}
          onEditSubAccounts={() => navigateModal('subAccounts')}
          onCancel={() => setOpen(false)}
          onSave={handleSave}
        />
      )}
    </Dialog>
  );
};
