import { useEffect, useState } from 'react';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { ServiceRequestForm } from './ServiceRequestForm';
import { useProductsStore } from '../../../store/Product';
import { useInventory } from '../../../store/Inventory';
import { Dialog } from '@mui/material';

interface ServiceRequestProps {
  ctaClassName: string;
}

export function ServiceRequestLink({ ctaClassName }: ServiceRequestProps) {
  const [open, setOpen] = useState(false);
  const { fetchProducts } = useProductsStore();
  const { fetchInventory } = useInventory();

  const onClose = async () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchProducts();
    fetchInventory();
  }, []);

  return (
    <>
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className={ctaClassName}
          data-cy="new-service-request"
          onClick={() => setOpen(true)}>
          New Request
        </button>
      </TextSpan>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}>
        <ServiceRequestForm
          style={{ padding: '28px' }}
          onClose={onClose}
        />
      </Dialog>
    </>
  );
}
