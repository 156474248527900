import { useEffect, useState } from 'react';
import { Icon } from '../../Atoms/Icon';
import { SrOnlyText, TextSpan } from '../../Atoms/Text';
import { IconButton } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import NewLocationInput from './NewLocationInput';

const helperStyle = {
  color: 'rgba(0, 0, 0, 0.6)',
  fontFamily: 'sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '1.66',
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginBottom: '0',
  marginLeft: '14px'
};

export interface MultiNewLocationInputProps {
  linkClassName?: string;
  limit?: number;
  limitText?: string;
  onLocationsChange?: (locations: string[]) => void;
}

export const MultiNewLocationInput = ({
  linkClassName,
  limit = 0,
  limitText = '',
  onLocationsChange
}: MultiNewLocationInputProps) => {
  const [locations, setLocations] = useState<string[]>([]);
  const [locationKeys, setLocationKeys] = useState<number[]>([]);

  useEffect(() => {
    onLocationsChange && onLocationsChange(locations);
  }, [locations]);

  const addLocation = () => {
    setLocations([...locations, '']);
    const newKey = locationKeys.length == 0 ? 0 : locationKeys[locationKeys.length - 1] + 1;
    setLocationKeys([...locationKeys, newKey]);
  };

  const updateLocation = (index: number, newValue: string) => {
    const newLoc = [...locations];
    newLoc[index] = newValue;
    setLocations(newLoc);
  };

  const removeLocation = (indexToRemove: number) => {
    setLocations(locations.filter((_, index) => index !== indexToRemove));
    setLocationKeys(locationKeys.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="w-full flex flex-col gap-2">
      {locations.map((location, index) => (
        <div
          key={locationKeys[index]}
          className="w-full flex flex-row gap-2">
          <NewLocationInput
            size="small"
            value={location}
            onChange={(newValue) => updateLocation(index, newValue)}
            required={true}
            label={`New Location ${index + 1}`}
          />
          <IconButton
            sx={{
              margin: 'auto'
            }}
            color="warning"
            onClick={(e) => {
              e.preventDefault();
              removeLocation(index);
            }}>
            <RemoveCircleIcon />
            <SrOnlyText>Remove Location {index + 1}</SrOnlyText>
          </IconButton>
        </div>
      ))}
      <>
        {limit > 0 && locations.length >= limit && <p style={helperStyle}>{limitText}</p>}
        {!(limit > 0 && locations.length >= limit) && (
          <TextSpan className="flex items-center space-x-2">
            <button
              className={`${linkClassName} flex flex-row gap-2 items-center`}
              data-cy="new-location"
              disabled={limit > 0 && locations.length >= limit}
              onClick={(e) => {
                e.preventDefault();
                addLocation();
              }}>
              <Icon type="plus" /> New Location
            </button>
          </TextSpan>
        )}
      </>
    </div>
  );
};

export default MultiNewLocationInput;
