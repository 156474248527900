import { callExternalApi } from '../callExternalApi';

export type ServiceRequest = {
  request_type: string;
  service_types: string[];
  locations: string[];
  new_locations: string[];
  preferred_term?: string;
  details: string;
};

export async function postServiceRequest(requestBody: ServiceRequest, accountId: string | undefined) {
  const url = 'api/service_requests';
  return await callExternalApi({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      AccountScope: accountId
    },
    data: { service_request: requestBody }
  });
}
