import { BehaviorSubject } from 'rxjs';
import { postServiceRequest, ServiceRequest } from '../Api/ServiceRequest/serviceRequestApi';
import { getAccountScope } from './User';

const serviceRequestSubject = new BehaviorSubject<ServiceRequest[]>([]);

export const getServiceRequestSubject = (): BehaviorSubject<ServiceRequest[]> => serviceRequestSubject;

export const clearServiceRequestStore = () => {
  serviceRequestSubject.next([]);
};

export const addServiceRequest = async (service_request: ServiceRequest) => {
  const currentValue = [...serviceRequestSubject.getValue()];

  const account = getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined;
  const resp = await postServiceRequest(service_request, account?.id);
  if (!resp.error) {
    serviceRequestSubject.next([...currentValue, service_request]);
    return service_request;
  } else {
    return;
  }
};
