import { useEffect, useState } from 'react';
import { Account, ContactUserData, UserRoles, UserTeam } from '../../../types';
import { getAccountContactSubject, getAccountSubject, getSfUsersSubject } from '../../../store/Accounts';
import { getFormsSubject, updateForm } from '../../../store/BulkForm';
import { AccountSelectionForm, RoleSelectionForm, UserContactDetails } from '../../Compounds/UserCreation';
import { SKIP_ACCOUNT_ROLES, getRoleSubject, getRoles } from '../../../store/Role';
import { isTeamManager, getUser, getDefaultPreferences } from '../../../store/User';
import { TeamSelectionForm } from '../../Compounds/UserCreation';
import { Team, getTeamsSubject, useTeamsStore } from '../../../store/Team';
import { UserFormProps } from '../../../store/PortalUser';
import {
  AccountCustomizationData,
  getAccountCustomizationsSubject,
  isCustom,
  useAccountCustomizationsStore
} from '../../../store/AccountCustomization';

export interface UserFormArgs {
  formId: string;
}

export const UserForm = ({ formId }: UserFormArgs) => {
  const teamManager = isTeamManager(getUser());
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [formData, setFormData] = useState<Partial<UserFormProps | undefined>>(undefined);
  const [accountContacts, setAccountContacts] = useState<ContactUserData[]>([]);
  const [filteredAccountContacts, setFilteredAccountContacts] = useState<ContactUserData[]>([]);
  const [sfUsers, setSfUsers] = useState<ContactUserData[]>([]);
  const [roles, setRoles] = useState<UserRoles[]>(getRoles());
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [accountTeams, setAccountTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<UserTeam | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<UserRoles[]>([]);
  const [contacts, setContacts] = useState<ContactUserData[]>([]);
  const [allowAccount, setAllowAccount] = useState<boolean>(!teamManager);
  const [acctCust, setAcctCust] = useState<AccountCustomizationData[]>([]);
  const { fetchTeams } = useTeamsStore();
  const { fetchAccountCustomizations } = useAccountCustomizationsStore();

  useEffect(() => {
    const contacts = selectedAccount?.id
      ? accountContacts.filter((c) => c.account_id === selectedAccount?.id)
      : accountContacts;
    setFilteredAccountContacts(contacts);
  }, [selectedAccount, accountContacts.length]);

  useEffect(() => {
    const acctTeams = selectedAccount?.id ? teams.filter((t) => t.account_id === selectedAccount?.id) : teams;
    setAccountTeams(acctTeams);
  }, [selectedAccount, accountTeams.length, teams.length]);

  // Component init
  useEffect(() => {
    const accountContactSub = getAccountContactSubject().subscribe((contacts) => {
      setAccountContacts(contacts);
      setContacts(contacts);
    });
    const accountSub = getAccountSubject().subscribe((accounts) => {
      setAccounts(accounts.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
    const roleSub = getRoleSubject().subscribe((roles) => setRoles(roles));
    const sfUserSub = getSfUsersSubject().subscribe((sfUsers) => setSfUsers(sfUsers));
    const formDataSub = getFormsSubject().subscribe((data) => {
      const formIndex = data.findIndex((item) => item?.id === formId);
      setFormData(data[formIndex]);
    });
    const teamsSub = getTeamsSubject().subscribe((teams) => setTeams(teams));
    const accCustSub = getAccountCustomizationsSubject().subscribe((cust) => setAcctCust(cust));

    fetchTeams();
    fetchAccountCustomizations();

    return () => {
      if (accountContactSub) accountContactSub?.unsubscribe();
      if (accountSub) accountSub?.unsubscribe();
      if (roleSub) roleSub?.unsubscribe();
      if (sfUserSub) sfUserSub?.unsubscribe();
      if (formDataSub) formDataSub?.unsubscribe();
      if (teamsSub) teamsSub.unsubscribe();
      if (accCustSub) accCustSub.unsubscribe();
    };
  }, []);

  const handleAccountChange = (acct: Account | undefined) => {
    setSelectedAccount(acct);

    const update: Partial<UserFormProps> = {
      ...formData,
      email: '',
      first_name: '',
      last_name: '',
      account_id: acct?.sfId,
      account_gri: acct?.id
    };

    const preferences = getDefaultPreferences();

    const accountCust = acctCust.find((i) => i.account_id === acct?.id);
    if (accountCust && accountCust.content) {
      if (accountCust.content.inventory_grid && isCustom(accountCust.content.inventory_grid)) {
        preferences.content.inventory_grid_All = accountCust.content.inventory_grid;
      }
      if (accountCust.content.widgets && isCustom(accountCust.content.widgets)) {
        preferences.content.widgets = accountCust.content.widgets;
      }
    }

    update.userCustomizations = preferences;

    updateForm(formId, update, 'user');
  };

  const handleTeamChange = (team: UserTeam | null) => {
    setSelectedTeam(team);
    const update: Partial<UserFormProps> = {
      ...formData,
      team_id: team?.id
    };
    updateForm(formId, update, 'user');
  };

  const handleRolesUpdate = (updatedRoles: string[]) => {
    setAllowAccount(!teamManager);
    setAccountContacts(contacts);

    const filteredRoles = roles.filter((role) => updatedRoles.includes(role.name as string));
    setSelectedRoles(filteredRoles);
    const update = { ...formData, roles_attributes: filteredRoles };
    updateForm(formId, update, 'user');
    const isCommissions = updatedRoles.length === 1 && updatedRoles[0] === 'Commissions';
    if (updatedRoles.some((item) => SKIP_ACCOUNT_ROLES.includes(item)) || isCommissions) {
      setSelectedAccount(undefined);
      setAllowAccount(false);
      setAccountContacts(isCommissions ? [] : sfUsers);
    }
  };

  const selectedAccountId = () => {
    if (!selectedRoles.map((r) => r.name).includes('Team Manager')) return null;

    return selectedAccount?.id;
  };

  const handleTeamCreate = async () => {
    setTeams(getTeamsSubject().value);
  };

  return (
    <>
      <form
        className="flex flex-wrap flex-row grow"
        data-testid="user-form"
        data-cy="bulk-user-form">
        <RoleSelectionForm
          selectedRoles={selectedRoles.map((role) => role.name)}
          updateRoles={handleRolesUpdate}
          drawer
          size="small"
          styles="w-40 lg:w-64 my-1"
        />
        <AccountSelectionForm
          accounts={accounts}
          selectedAccount={selectedAccount || null}
          setSelectedAccount={handleAccountChange}
          size="small"
          styles="w-36 lg:w-64 my-1 ml-4"
          disabled={!allowAccount}
        />
        {!teamManager && (
          <TeamSelectionForm
            handleTeamCreate={handleTeamCreate}
            selectedAccountId={selectedAccountId()}
            teams={accountTeams}
            selectedTeam={selectedTeam}
            setSelectedTeam={handleTeamChange}
            size="small"
            styles="w-40 lg:w-62 my-1 ml-4 lg:mr-4"
          />
        )}
        <UserContactDetails
          accountContacts={filteredAccountContacts}
          formId={formId}
          accountId={selectedAccount?.id}
          userDetails={formData}
          disableAll={(allowAccount && !selectedAccount) || false}
        />
      </form>
    </>
  );
};
