import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  AccountCustomizationData,
  Customizations,
  useAccountCustomizationsStore
} from '../../../../../store/AccountCustomization';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { SettingsModalContent } from '../../../UserCreationPage/SettingsModalContent';
import { useEffect, useState } from 'react';
import { PostAccountCustomizationRequest } from '../../../../../Api/Preferences/AccountCustomizations/accountCustomizationsApi';
import { useSnackbar } from '../../../../../Context/SnackbarContext';
import { GridPreferences, Widgets } from '../../../../../store/User';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../../../utils/styleHelpers';
import { ComponentSpinner } from '../../../../Compounds/Loading/ComponentSpinner';
import { useAccounts } from '../../../../../store/Accounts';
import { DisableCustomizationsForm } from '../../../../Compounds/DisableCustomizationsForm/DisableCustomizationsForm';

export interface CustomizationsParams {
  data: AccountCustomizationData;
}

export const AccountCustomizations = ({ data }: CustomizationsParams) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCustomizations, setNewCustomizations] = useState<AccountCustomizationData>(
    JSON.parse(JSON.stringify(data))
  );
  const [title, setTitle] = useState<string>('');
  const { updateRequest, createRequest, fetchAccountCustomizations } = useAccountCustomizationsStore();
  const { setSnack } = useSnackbar();
  const { fetchAccounts } = useAccounts();

  useEffect(() => {
    setNewCustomizations(JSON.parse(JSON.stringify(data)));
  }, [data.account_id]);

  const handleEditColumns = () => {
    setOpen(true);
    setTitle('Inventory');
  };

  const handleEditWidgets = () => {
    setOpen(true);
    setTitle('Widgets');
  };

  const handleSetGridPreferences = (prefs: GridPreferences) => {
    const update = { ...newCustomizations };
    update.content.inventory_grid = prefs;
    setNewCustomizations(update);
  };

  const handleSave = async () => {
    if (data.account_id) {
      setLoading(true);
      const req: PostAccountCustomizationRequest = {
        account_id: data.account_id,
        account_name: data?.account_name || '',
        content: newCustomizations.content
      };

      const res = data?.id ? await updateRequest(data?.id, req, title) : await createRequest(req);

      setOpen(false);

      if (res?.error) {
        setSnack({
          message: res.error.message,
          open: true,
          type: 'error'
        });
      } else {
        setSnack({
          message: 'Settings Saved',
          open: true,
          type: 'success'
        });

        await fetchAccountCustomizations();
        await fetchAccounts(true);
      }
      setLoading(false);
    }
  };

  const widgetsDefault = () => {
    const newWidgets = newCustomizations?.content?.widgets?.home;
    return newWidgets?.every((w) => w?.disabled === false);
  };

  const columnsDefault = () => {
    const disabledFields = newCustomizations?.content?.inventory_grid?.disabledFields;
    return disabledFields?.length === 0;
  };

  const handleWidgetChange = (customizations: Customizations) => {
    const update = { ...newCustomizations };
    update.content.widgets = customizations as Widgets;
    setNewCustomizations(update);
  };

  const handleCommentChange = async () => {
    const update = { ...newCustomizations };
    update.content.comments = !update.content.comments;
    setNewCustomizations(update);
    await handleSave();
  };

  const handleSupplierContactsChange = async () => {
    const update = { ...newCustomizations };
    update.content.supplier_contacts = !update.content.supplier_contacts;
    setNewCustomizations(update);
    await handleSave();
  };

  return (
    <DetailViewCard
      collapsed={false}
      dataCy="account-customizations-card"
      title="Customizations"
      enableCollapse>
      <div className="w-11/12 m-auto">
        <SettingsModalContent
          columnsCustom={!columnsDefault()}
          onEditColumns={handleEditColumns}
          widgetsCustom={!widgetsDefault()}
          onEditWidgets={handleEditWidgets}
          handleCommentChange={handleCommentChange}
          handleSupplierContactsChange={handleSupplierContactsChange}
          newCustomizations={newCustomizations}
        />
      </div>
      <Dialog
        open={open}
        sx={{
          height: '50rem'
        }}
        onClose={() => setOpen(false)}
        data-testid="edit-account-settings-modal">
        <IconButton
          data-testid="customizations-close-button"
          data-cy="account-customizations-modal-close-button"
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: '2rem' }}>
          {title === 'Inventory' && newCustomizations && (
            <DisableCustomizationsForm
              type="column"
              header="Table Customizations"
              subheader="Enable and disable table columns for the users you're creating."
              gridPrefs={newCustomizations.content.inventory_grid || []}
              className="flex"
              saveHandler={(v) => handleSetGridPreferences(v as GridPreferences)}
            />
          )}
          {title === 'Widgets' && (
            <DisableCustomizationsForm
              type="widget"
              header="User Widget Customizations"
              subheader="Enable and disable widgets for the users you're creating."
              widgets={newCustomizations.content.widgets || []}
              className="flex"
              saveHandler={handleWidgetChange}
            />
          )}
        </DialogContent>
        <DialogActions className="flex mx-4 mb-3 space-x-4">
          {loading && <ComponentSpinner label="Saving..." />}
          {!loading && (
            <>
              <button
                className={DEFAULT_CANCEL_BUTTON_STYLE}
                data-testid="account-customizations-cancel"
                data-cy="account-customizations-modal-cancel"
                onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className={DEFAULT_ADVANCE_BUTTON_STYLE}
                onClick={handleSave}>
                Save
              </button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </DetailViewCard>
  );
};
