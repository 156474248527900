import { DialogActions, DialogContent } from '@mui/material';
import { TextSpan } from '../../Atoms/Text';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { Account } from '../../../types';

export interface SettingsModalMainProps {
  username: string;
  columnsCustom: boolean;
  widgetsCustom: boolean;
  subAccountsCustom: boolean;
  subAccounts?: Account[];
  onEditColumns: () => void;
  onEditWidgets: () => void;
  onEditSubAccounts: () => void;
  onCancel: () => void;
  onSave: () => void;
}

export const SettingsModalMain = ({
  columnsCustom,
  username,
  widgetsCustom,
  subAccountsCustom,
  subAccounts,
  onEditColumns,
  onEditWidgets,
  onEditSubAccounts,
  onCancel,
  onSave
}: SettingsModalMainProps) => {
  return (
    <>
      <DialogContent>
        <div className="flex flex-col px-8 py-2 max-w-xl m-auto justify-center h-72">
          <div className="border-b-2 w-full mb-4">
            <TextSpan
              size="xl"
              dataTestId="account-customizations-title">
              Customizations for {username}
            </TextSpan>
          </div>
          <>
            <div className="flex mt-2">
              <div className="grow">
                <TextSpan
                  className="flex items-center"
                  color="grey10"
                  size="lg">
                  Table Columns:
                </TextSpan>
              </div>
              <div className="flex-none">
                <TextSpan
                  className="flex items-center"
                  color="grey10"
                  size="sm">
                  {columnsCustom ? 'Custom' : 'Default'}
                </TextSpan>
              </div>
              <div className="flex-none">
                <button
                  className="mx-2 flex items-center text-sm text-indigo hover:text-indigo-mid"
                  onClick={onEditColumns}>
                  Edit
                </button>
              </div>
            </div>
            <div className="border-b-2 pb-3.5 mb-3.5">
              <TextSpan
                color="grey5"
                dataTestId="table-columns-title"
                size="xs">
                Set the default order and visibility of columns for this user.
              </TextSpan>
            </div>
          </>
          <>
            <div className="flex mt-2">
              <div className="grow">
                <TextSpan
                  className="flex items-center"
                  color="grey10"
                  size="lg">
                  Widgets:
                </TextSpan>
              </div>
              <div className="flex-none">
                <TextSpan
                  className="flex items-center"
                  color="grey10"
                  size="sm">
                  {widgetsCustom ? 'Custom' : 'Default'}
                </TextSpan>
              </div>
              <div className="flex-none">
                <button
                  className="mx-2 flex items-center text-sm text-indigo hover:text-indigo-mid"
                  onClick={onEditWidgets}>
                  Edit
                </button>
              </div>
            </div>
            <div className="border-b-2 pb-3.5 mb-2">
              <TextSpan
                color="grey5"
                dataTestId="table-columns-title"
                size="xs">
                Set the default order and visibility of widgets for this user.
              </TextSpan>
            </div>
          </>
          {subAccounts != undefined && subAccounts.length > 0 && (
            <>
              <div className="flex mt-2">
                <div className="grow">
                  <TextSpan
                    className="flex items-center"
                    color="grey10"
                    size="lg">
                    Sub-Accounts:
                  </TextSpan>
                </div>
                <div className="flex-none cursor-default">
                  <TextSpan
                    className="flex items-center"
                    color="grey10"
                    size="sm">
                    {subAccountsCustom ? 'Custom' : 'Default'}
                  </TextSpan>
                </div>
                <div className="flex-none">
                  <button
                    className="mx-2 flex items-center text-sm text-indigo hover:text-indigo-mid"
                    onClick={onEditSubAccounts}>
                    Edit
                  </button>
                </div>
              </div>
              <div className="border-b-2 pb-3.5 mb-2">
                <TextSpan
                  color="grey5"
                  dataTestId="table-columns-title"
                  size="xs">
                  Set the default sub-accounts for this user.
                </TextSpan>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className="flex mx-4 mb-3 space-x-4">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={onCancel}>
          Cancel
        </button>
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={onSave}>
          Save
        </button>
      </DialogActions>
    </>
  );
};
