import { useEffect, useState } from 'react';
import { InventoryAsset, SupportTicketsItem } from '../../../types';
import {
  getNewTicketCreatedSubject,
  getNewTicketProcessingSubject,
  getTicketLoadingSubject,
  getTicketsByAssetId,
  getTicketSubject,
  newTicketCreatedSubject,
  useSupportTickets
} from '../../../store/SupportTicket';
import { TicketLink } from '../../Compounds/UpstackDataGrid/CellRenderers';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { DetailViewCard } from '../../Compounds/CardWithTitle';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { SupportTicketWizard } from '../../Compounds/Wizards/SupportTicketWizard';
import { Permission } from '../../../store/GeneralStore';
import { getRoute1Subject } from '../../../store/Route1';

export interface TicketDetailsDisplayProps {
  data: InventoryAsset;
  onTicketsChanged?: () => Promise<void>;
  disabled?: boolean;
  disabledMessage?: string;
}

export function TicketDetailsDisplay({
  data,
  onTicketsChanged,
  disabled = false,
  disabledMessage = ''
}: TicketDetailsDisplayProps) {
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const [ticketsProcessing, setTicketsProcessing] = useState<boolean>(false);
  const [newTicketCreated, setNewTicketCreated] = useState<boolean>(false);

  const [ticketsCollapsed, setTicketsCollapsed] = useState<boolean>(true);
  const [tickets, setTickets] = useState<SupportTicketsItem[]>([]);
  const { fetchTickets } = useSupportTickets();

  useEffect(() => {
    const ticketLoadingSub = getTicketLoadingSubject().subscribe((state) => setTicketsLoading(state));
    const ticketSub = getTicketSubject().subscribe(() => setTickets(getTicketsByAssetId(data.sfId)));
    const ticketProcessingSub = getNewTicketProcessingSubject().subscribe((p) => setTicketsProcessing(p));
    const newTicketSub = getNewTicketCreatedSubject().subscribe((n) => setNewTicketCreated(n));
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && fetchTickets(true));

    fetchTickets();

    return () => {
      if (ticketLoadingSub) ticketLoadingSub.unsubscribe();
      if (ticketSub) ticketSub.unsubscribe();
      if (ticketProcessingSub) ticketProcessingSub.unsubscribe();
      if (newTicketSub) newTicketSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
      setTicketsCollapsed(true);
      newTicketCreatedSubject.next(false);
    };
  }, []);

  useEffect(() => {
    setTickets(getTicketsByAssetId(data.sfId));
  }, [data?.id]);

  useEffect(() => {
    if (newTicketCreated) setTicketsCollapsed(false);
  }, [newTicketCreated]);

  const cardActions = () => (
    <PermissibleRender requiredPermissions={[Permission.CREATE_TICKETS_FROM_INVENTORY]}>
      <SupportTicketWizard
        ctaClassName="text-[indigo] text-sm"
        inventoryAsset={data}
        onFormCloseHandler={async () => ticketsChanged()}
        disabled={disabled}
        disabledMessage={disabledMessage}
      />
    </PermissibleRender>
  );

  const ticketsChanged = async () => {
    fetchTickets(true);
    if (onTicketsChanged) {
      await onTicketsChanged();
    }
  };

  return (
    <DetailViewCard
      cardActions={cardActions()}
      collapsed={ticketsCollapsed}
      enableCollapse
      key="inventory-tickets"
      title={`Support Tickets (${tickets?.length || 0})`}>
      <div className="max-h-72">
        {(ticketsLoading || ticketsProcessing) && (
          <div className="mt-28">
            <ComponentSpinner />
          </div>
        )}
        {!disabled && !ticketsLoading && !tickets?.length && <div className="text-center">No Tickets</div>}
        {disabled && <div className="text-center">{disabledMessage}</div>}
        {!ticketsLoading && !!tickets?.length && (
          <div className="h-60 overflow-auto">
            <table
              data-testid="table"
              className="w-full [&_tr]:border-y [&_tr]:h-[30px]">
              <thead>
                <tr className="text-indigo-mid text-xs text-left [&>*]:font-normal truncate [&>*]:p-2">
                  <th className="border-r"></th>
                  <th className="border-r">Status</th>
                  <th className="border-r">Case Number</th>
                  <th className="border-r">Issue</th>
                  <th className="border-r">Date/Time Opened</th>
                </tr>
              </thead>
              <tbody className="h-2/3 overflow-scroll">
                {tickets?.map((ticket) => (
                  <tr
                    key={`${ticket.caseNumber}-${ticket.id}`}
                    className="text-black text-sm text-left font-normal truncate [&>*]:p-[0.65rem]">
                    <td className="pt-[-20px] border-r min-w-[38px]">
                      <TicketLink
                        data={ticket}
                        classNames="h-[100%]"
                      />
                    </td>
                    <td className="border-r min-w-[90px] text-ellipsis">{ticket.status}</td>
                    <td className="border-r min-w-[100px]">{ticket.caseNumber}</td>
                    <td className="border-r min-w-[100px]">{ticket.issue}</td>
                    <td className="border-r min-w-[100px]">{ticket.dateTimeOpened}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </DetailViewCard>
  );
}
