import { useEffect, useState } from 'react';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Account } from '../../../types';
import { DEFAULT_CANCEL_BUTTON_STYLE, DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { BasicAccountMultiSelect } from '../../Compounds/BasicAccountMultiSelect';
import { TextBlock } from '../../Atoms/Text';

export interface SettingsModalSubAccountsFormProps {
  accounts: Account[];
  selectedAccounts: Account[];
  handleAccountSelect: (acct: Account[] | null) => void;
  onBack: () => void;
  onSave: () => void;
}

export const SettingsModalSubAccountsForm = ({
  accounts,
  selectedAccounts,
  handleAccountSelect,
  onBack,
  onSave
}: SettingsModalSubAccountsFormProps) => {
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Account[] | null) => {
    handleAccountSelect(value);
  };

  const handleSelectAll = () => {
    handleAccountSelect(accounts);
  };

  const handleSelectNone = () => {
    handleAccountSelect([]);
  };

  useEffect(() => {
    const selectedIds = selectedAccounts?.map((acc) => acc.id);

    const displayAcct = accounts.reduce((acc: Account[], element) => {
      if (selectedIds?.includes(element?.id)) {
        return [element, ...acc];
      }

      return [...acc, element];
    }, []);

    setDisplayAccounts(displayAcct);
  }, []);

  return (
    <>
      <div className="flex flex-col px-3 max-w-xl m-auto pt-3">
        <DialogTitle>Sub Accounts</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center h-96">
            <div className="flex flex-col justify-between">
              <BasicAccountMultiSelect
                selectedAccounts={selectedAccounts}
                displayAccounts={displayAccounts}
                handleSelectAll={handleSelectAll}
                handleChange={handleChange}
                handleSelectNone={handleSelectNone}
              />
            </div>
          </div>
        </DialogContent>
      </div>
      <div className="flex flex-row px-9 max-w-xl pb-3">
        <TextBlock
          color="grey7"
          size="sm">
          {selectedAccounts.length} of {displayAccounts.length} selected
        </TextBlock>
      </div>
      <DialogActions className="flex mx-4 mb-3 space-x-4">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={onBack}>
          Back
        </button>
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={onSave}>
          Set
        </button>
      </DialogActions>
    </>
  );
};
