import { useEffect, useState } from 'react';
import { TextSpan, TextBlock } from '../../Atoms/Text';
import { useForm } from 'react-hook-form';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { InventoryAsset, Product } from '../../../types';
import { ServiceRequest } from '../../../Api/ServiceRequest/serviceRequestApi';
import { Autocomplete, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { getProductSubject } from '../../../store/Product';
import { getInventorySubject } from '../../../store/Inventory';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { addServiceRequest } from '../../../store/ServiceRequest';
import { MultiNewLocationInput } from '../MultiNewLocationInput';

const typeOfRequestValues: string[] = ['New Service', 'Move Existing Service', 'Disconnect a Service', 'Other'];
const preferredTermOptions: string[] = ['12 Months', '24 Months', '36 Months', 'Month-to-Month', 'Other'];
const newLocationLimitText: string =
  'Add up to 4 locations that you have needs for or represent a region. We’ll gather the rest of your requirements when we talk.';

export interface FormData {
  request_type: string;
  service_types: string[];
  preferred_term: string;
  details: string;
}

export interface ServiceRequestFormProps {
  onClose: () => void;
  style: React.CSSProperties;
}

export function ServiceRequestForm({ onClose, style = {} }: ServiceRequestFormProps) {
  const [disabled, setDisabled] = useState(false);
  const [requestType, setRequestType] = useState<string>(typeOfRequestValues[0]);
  const [accountProducts, setAccountProducts] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productOptions, setProductOptions] = useState<Product[]>([]);
  const [existingInventory, setExistingInventory] = useState<InventoryAsset[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const [productSelections, setProductSelections] = useState<Product[]>([]);
  const [locationSelections, setLocationSelections] = useState<string[]>([]);
  const [newLocations, setNewLocations] = useState<string[]>([]);
  const { register, handleSubmit } = useForm<FormData>();
  const { setSnack } = useSnackbar();

  useEffect(() => {
    const productSub = getProductSubject().subscribe((products) => {
      if (requestType == typeOfRequestValues[0] || requestType == typeOfRequestValues[3]) {
        setProductOptions(products);
      }
      setAllProducts(products);
    });
    const inventorySub = getInventorySubject().subscribe((inventory) => {
      setExistingInventory(inventory);
      const managedInventory = inventory.filter((i) => i['UPSTACK Managed']);
      const managedProducts = managedInventory
        .map((i) => i.product)
        .filter((value, index, self) => self.findIndex((p) => p.id == value.id) === index)
        .sort((a, b) => {
          return a.name > b.name ? 1 : a.name === b.name ? 0 : -1;
        });
      if (requestType == typeOfRequestValues[1] || requestType == typeOfRequestValues[2]) {
        setProductOptions(managedProducts);
      }
      setAccountProducts(managedProducts);
      updateFilteredLocations(inventory);
    });

    return () => {
      if (productSub) productSub.unsubscribe();
      if (inventorySub) inventorySub.unsubscribe();
    };
  }, []);

  const updateFilteredLocations = (inventory: InventoryAsset[] = [], filteredProducts: Product[] = []) => {
    const inv = inventory.length > 0 ? inventory : existingInventory;
    let locations: string[] = [];
    if (requestType == typeOfRequestValues[0] || requestType == typeOfRequestValues[3]) {
      locations = inv
        .map((inventory) => inventory.address)
        .filter((value, index, self) => self.findIndex((p) => p == value) === index)
        .sort();
      setFilteredLocations(locations);
    } else {
      locations = inv
        .filter(
          (inventory) =>
            filteredProducts.length == 0 || filteredProducts.find((prod) => prod.id == inventory.product.id)
        )
        .map((inventory) => inventory.address)
        .filter((value, index, self) => self.findIndex((p) => p == value) === index)
        .sort();
      setFilteredLocations(locations);
    }
    setLocationSelections(locationSelections.filter((loc) => locations.findIndex((option) => option == loc) > -1));
  };

  const handleSubmitForm = async (formData: FormData): Promise<void> => {
    setDisabled(true);
    const requestBody: ServiceRequest = {
      request_type: formData.request_type,
      service_types: productSelections.map((prod) => `${prod.family} - ${prod.name}`),
      locations: locationSelections,
      new_locations: newLocations,
      details: formData.details
    };
    if (formData.request_type == typeOfRequestValues[0]) {
      requestBody.preferred_term = formData.preferred_term;
    }
    const service_request = await addServiceRequest(requestBody);
    if (service_request) {
      setSnack({ message: 'Your email has been sent successfully.', type: 'success', open: true });
      onClose();
    } else {
      setSnack({ message: 'There was an error sending your request.', type: 'error', open: true });
      setDisabled(false);
    }
  };

  const productSelectionChanged = (e: React.SyntheticEvent<Element, Event>, value: Product[]) => {
    const newProductSelections = productOptions.filter((option) => value.findIndex((val) => val.id == option.id) > -1);
    setProductSelections(newProductSelections);
    if (requestType == typeOfRequestValues[1] || requestType == typeOfRequestValues[2]) {
      updateFilteredLocations(existingInventory, newProductSelections);
    }
  };

  const newLocationsChanged = (newValue: string[]) => {
    setNewLocations(newValue);
  };

  const requestTypeChanged = (event: SelectChangeEvent) => {
    setRequestType(event.target.value);
    setProductSelections([]);
    if (event.target.value == typeOfRequestValues[1] || event.target.value == typeOfRequestValues[2]) {
      setProductOptions(accountProducts);
    } else {
      setProductOptions(allProducts);
    }
  };

  const preferredTermChanged = (event: SelectChangeEvent) => {};

  const locationSelectionChanged = (e: React.SyntheticEvent<Element, Event>, value: string[]) => {
    setLocationSelections(value);
  };

  return (
    <div style={style}>
      <TextBlock className="text-xl">Start a New Request</TextBlock>
      <TextSpan
        className="py-2 text-[13px] block mb-2"
        color="grey7"
        size="sm14">
        Submit a new request and your Customer Success Manager will reach out to you shortly to learn more.
      </TextSpan>
      <form
        data-cy="service-request"
        aria-label="Service Request"
        onSubmit={handleSubmit(handleSubmitForm)}
        className="flex flex-col items-start gap-6 mt-5">
        <TextField
          select
          required
          disabled={disabled}
          sx={{ width: '100%' }}
          label="Type of Request"
          id="request_type_select"
          defaultValue={typeOfRequestValues[0]}
          data-cy="request_type"
          size="small"
          aria-label="type of request"
          {...register('request_type', { onChange: requestTypeChanged })}>
          {typeOfRequestValues.map((value) => (
            <MenuItem
              key={value}
              value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          multiple
          disabled={disabled}
          sx={{ width: '100%' }}
          size="small"
          options={productOptions}
          value={productSelections}
          onChange={productSelectionChanged}
          getOptionLabel={(option: Product) => `${option.family} - ${option.name}`}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.id}>
              {option.family} - {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type of Service*"
              inputProps={{
                ...params.inputProps,
                required: productSelections.length == 0
              }}
            />
          )}
        />
        <Autocomplete
          multiple
          disabled={disabled}
          sx={{ width: '100%' }}
          size="small"
          options={filteredLocations}
          value={locationSelections}
          onChange={locationSelectionChanged}
          getOptionDisabled={(option) => locationSelections.length > 3 && locationSelections.indexOf(option) == -1}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={
                locationSelections.length > 3 &&
                'Select up to 4 locations that you have needs for or represent a region. We’ll gather the rest of your requirements when we talk.'
              }
              label="Locations"
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
        <MultiNewLocationInput
          linkClassName="text-[indigo] text-sm"
          limit={4}
          limitText={newLocationLimitText}
          onLocationsChange={newLocationsChanged}></MultiNewLocationInput>
        <TextField
          select
          required={requestType == typeOfRequestValues[0]}
          sx={{ width: '100%' }}
          label="Preferred Term"
          id="preferred_term_select"
          defaultValue={preferredTermOptions[0]}
          data-cy="preferred_term"
          size="small"
          aria-label="preferred term"
          disabled={disabled || requestType != typeOfRequestValues[0]}
          {...register('preferred_term', { onChange: preferredTermChanged })}>
          {preferredTermOptions.map((value) => (
            <MenuItem
              key={value}
              value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Details for UPSTACK to Know"
          id="details_textarea"
          data-cy="details"
          aria-label="details"
          sx={{ width: '100%' }}
          disabled={disabled}
          minRows={3}
          multiline
          {...register('details')}></TextField>
        <hr className="w-full" />
        <div className="flex w-full flex-row justify-end gap-3">
          <button
            className={DEFAULT_CANCEL_BUTTON_STYLE}
            onClick={onClose}
            disabled={disabled}
            type="button">
            Cancel
          </button>
          <button
            type="submit"
            disabled={disabled}
            className={DEFAULT_ADVANCE_BUTTON_STYLE}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
