import { Tooltip } from '@mui/material';
import { Icon } from '../../Atoms/Icon';

export interface DisabledSupportTicketButtonProps {
  ctaClassName: string;
}

export function DisabledSupportTicketButton({ ctaClassName }: DisabledSupportTicketButtonProps) {
  const message =
    'Support Ticket creation is currently down for maintenance please reach out to support@upstack.com for assistance';
  return (
    <Tooltip
      title={message}
      placement="top"
      arrow>
      <div className="flex items-center space-x-2 w-full">
        <Icon type="plusDisabled" />
        <button
          className={`${ctaClassName} text-grey-5`}
          data-cy="new-ticket-disabled"
          disabled>
          New Ticket
        </button>
      </div>
    </Tooltip>
  );
}
