import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import {
  fetchAccountCustomizationsApi,
  PostAccountCustomizationRequest,
  postAccountCustomizationApi,
  updateAccountCustomizationApi
} from '../Api/Preferences/AccountCustomizations/accountCustomizationsApi';
import { HomeWidgetDefs } from '../components/Pages/HomePage/HomeWidgetDefs';
import { TicketsWidgetDefs } from '../components/Pages/SupportTickets/TicketsWidgetDefs';
import { InventoryWidgetDefs } from '../components/Pages/Inventory/InventoryWidgetDefs';
import { FormStateStatus } from './BulkForm';
import { GridPreferences, WidgetPreferences, Widgets, gridInitialState } from './User';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { DefaultInventoryGridColumnDefs } from '../components/Pages/Inventory/inventoryGridColumnDefs';
import { Account } from '../types';

export type AccountCustomizationContent = {
  widgets: Widgets;
  inventory_grid: GridPreferences;
  customizations?: string;
  sub_accounts?: Account[];
  additionalRecipients?: string[];
  comments?: boolean;
  supplier_contacts?: boolean;
};

export type AccountCustomizationData = {
  id?: string;
  account_name: string | undefined;
  account_id: string | undefined;
  updated_at: string;
  content: AccountCustomizationContent;
};

export const defaultAccountCustomizationData = (account_id?: string, account_name?: string) => ({
  account_id: account_id || '',
  account_name: account_name || '',
  content: {
    inventory_grid: {
      muiConfig: gridInitialState(DefaultInventoryGridColumnDefs) as GridInitialStatePro,
      disabledFields: []
    },
    widgets: {
      home: [...HomeWidgetDefs],
      tickets: [...TicketsWidgetDefs],
      inventory: [...InventoryWidgetDefs]
    },
    sub_accounts: [],
    additionalRecipients: []
  },
  updated_at: ''
});

export type Customizations = GridPreferences | Widgets;

export interface AccountCustomizationFormProps extends PostAccountCustomizationRequest {
  dirty: boolean;
  valid: boolean;
  id: string;
  ui_element: React.ReactElement;
  widgets_custom: boolean;
  inventory_grid_custom: boolean;
}

export interface CreateAccountCustomizationState {
  status: FormStateStatus;
  error?: string;
  data?: Partial<AccountCustomizationData>;
  requestBody: PostAccountCustomizationRequest;
}

export const isColumnCustomization = (cust: Customizations): cust is GridPreferences =>
  (cust as GridPreferences)?.muiConfig !== undefined && (cust as GridPreferences).disabledFields !== undefined;

export const isWidgetCustomization = (cust: Customizations): cust is Widgets =>
  (cust as Widgets)?.home !== undefined &&
  (cust as Widgets)?.tickets !== undefined &&
  (cust as Widgets)?.inventory !== undefined;

export const isWidgetPreference = (cust: unknown): cust is WidgetPreferences =>
  (cust as WidgetPreferences).widgetId !== undefined;

export const updateWidgetDef = (widget: WidgetPreferences, updates: WidgetPreferences[]): WidgetPreferences => {
  const matched = updates.find((w) => w.widgetId === widget.widgetId);
  return matched || widget;
};

export const isCustom = (customizations: Customizations, asUser = false, defaultDefs?: Customizations): boolean => {
  if (isColumnCustomization(customizations)) {
    return isGridCustom(customizations, asUser, defaultDefs as GridPreferences);
  }

  if (isWidgetCustomization(customizations)) {
    return isWidgetsCustom(customizations, asUser, defaultDefs as Widgets);
  }

  return false;
};

const isGridCustom = (grid: GridPreferences, asUser = false, defaultDef?: GridPreferences) => {
  if (asUser) {
    const { columnVisibilityModel: visibilityDefault = {}, orderedFields: orderedDefault = [] } =
      defaultDef?.muiConfig.columns || {};
    const { columnVisibilityModel: visibilityCurr = {}, orderedFields: orderedCurr = [] } =
      grid.muiConfig.columns || {};
    const orderCustom = orderedCurr.every((item, idx) => item === orderedDefault[idx]);
    const visibiltyCustom = Object.keys(visibilityCurr).every((key) => visibilityCurr[key] === visibilityDefault[key]);

    return orderCustom || visibiltyCustom;
  }
  return grid.disabledFields.length > 0;
};

const isWidgetsCustom = (widgets: Widgets, asUser = false, defaultDef?: Widgets) => {
  return Object.keys(widgets).reduce((isCustom: boolean, key: string): boolean => {
    if (isCustom) return isCustom;
    const widgetCustomizations = widgets[key as keyof Widgets];
    const defaultWidget = (defaultDef || {})[key as keyof Widgets] || [];
    if (asUser) {
      isCustom = widgetCustomizations.some((cust, idx) => {
        const matchingDefIdx = defaultWidget.findIndex((def) => def.widgetId === cust.widgetId);
        return cust.hide || (idx !== matchingDefIdx && matchingDefIdx !== -1);
      });
    } else {
      isCustom = widgetCustomizations.some((c) => c.disabled);
    }
    return isCustom;
  }, false);
};

const accountCustomizationsSubject = new BehaviorSubject<AccountCustomizationData[]>([]);
const accountCustomizationCreateStateSubject = new BehaviorSubject<CreateAccountCustomizationState | null>(null);
const formValidSubject = new BehaviorSubject<boolean>(false);
const accountCustomizationsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getAccountCustomizationsLoadingSubject = (): BehaviorSubject<boolean> =>
  accountCustomizationsLoadingSubject;

export const getAccountCustomizationsSubject = (): BehaviorSubject<AccountCustomizationData[]> =>
  accountCustomizationsSubject;
export const getAccountCustomizationCreateStateSubject = (): BehaviorSubject<CreateAccountCustomizationState | null> =>
  accountCustomizationCreateStateSubject;
export const clearAccountCustomizationCreateStateSubject = () => accountCustomizationCreateStateSubject.next(null);
export const getFormValidSubject = (): BehaviorSubject<boolean> => formValidSubject;
export const getAccountCustomizationsById = (id: string): AccountCustomizationData | undefined => {
  return accountCustomizationsSubject.getValue().find((c) => c.account_id === id);
};

export const getAcctCustByAccountId = (id: string | undefined): AccountCustomizationData | undefined => {
  const customizations = accountCustomizationsSubject.getValue();
  return customizations.find((c) => c.account_id === id);
};

export interface UseAccountCustomizationsStoreReturn {
  fetchAccountCustomizations: () => Promise<void>;
  createRequest: (requestBody: PostAccountCustomizationRequest) => Promise<void>;
  clear: () => void;
  setAccountCustomizationsData: (accountCustomizations: AccountCustomizationData[]) => void;
  updateRequest: (
    id: string,
    requestBody: PostAccountCustomizationRequest,
    type?: string
  ) => Promise<{ error: { message: string } } | undefined>;
}

export const useAccountCustomizationsStore = (): UseAccountCustomizationsStoreReturn => {
  const fetchAccountCustomizations = async (): Promise<void> => {
    accountCustomizationsLoadingSubject.next(true);

    const { data, error } = await fetchAccountCustomizationsApi();

    if (data) {
      const formattedCust = data.map((cust) => {
        const content = cust?.content;
        if (!content) return cust;

        const vals = [];

        const existingGrids = content.inventory_grid || [];

        if (isCustom(content.widgets)) vals.push('Widgets');
        if (isCustom(existingGrids)) vals.push('Table Columns');

        cust.content.customizations = vals.join(', ');
        return cust;
      });
      accountCustomizationsSubject.next(formattedCust);
    }
    if (error) addError('account-customization', error.message);

    accountCustomizationsLoadingSubject.next(false);
  };

  const createRequest = async (requestBody: PostAccountCustomizationRequest) => {
    const { data, error } = await postAccountCustomizationApi(requestBody);
    if (data) {
      accountCustomizationCreateStateSubject.next({
        status: FormStateStatus.SUCCESS,
        data,
        error: undefined,
        requestBody
      });
    }

    if (error) {
      accountCustomizationCreateStateSubject.next({
        status: FormStateStatus.FAILED,
        data: requestBody,
        error: error.message,
        requestBody
      });
    }
  };

  const updateRequest = async (id: string, requestBody: PostAccountCustomizationRequest, type?: string) => {
    const { data, error } = await updateAccountCustomizationApi(id, requestBody, type);
    if (data?.account) {
      accountCustomizationCreateStateSubject.next({
        status: FormStateStatus.SUCCESS,
        data: data.account,
        error: undefined,
        requestBody
      });
    }

    if (error) return { error };
  };

  const setAccountCustomizationsData = (accountCustomizations: AccountCustomizationData[]) =>
    accountCustomizationsSubject.next(accountCustomizations);

  const clear = () => accountCustomizationsSubject.next([]);

  return {
    fetchAccountCustomizations,
    createRequest,
    clear,
    setAccountCustomizationsData,
    updateRequest
  };
};
