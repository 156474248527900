import { Account } from '../../../types';
import { useEffect, useState } from 'react';
import { getAccounts, getSelectedAccountsSubject } from '../../../store/User';
import { BasicAccountMultiSelect } from '../BasicAccountMultiSelect';

export interface AccountMultiSelectProps {
  accounts: Account[];
  handleAccountSelect: (acct: Account[] | null) => void;
}

export const AccountMultiSelect = ({ accounts, handleAccountSelect }: AccountMultiSelectProps) => {
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>([]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Account[] | null) => {
    handleAccountSelect(value);
  };

  const handleSelectAll = () => {
    handleAccountSelect(getAccounts() || []);
  };

  const handleSelectNone = () => {
    handleAccountSelect([]);
  };

  useEffect(() => {
    const selectedAcctSub = getSelectedAccountsSubject().subscribe((acct) => {
      setSelectedAccounts(acct);
      const selectedIds = acct?.map((acc) => acc.id);

      const displayAcct = accounts.reduce((acc: Account[], element) => {
        if (selectedIds?.includes(element?.id)) {
          return [element, ...acc];
        }

        return [...acc, element];
      }, []);

      setDisplayAccounts(displayAcct);
    });

    return () => {
      if (selectedAcctSub) selectedAcctSub.unsubscribe();
    };
  }, []);

  return (
    <BasicAccountMultiSelect
      displayAccounts={displayAccounts}
      selectedAccounts={selectedAccounts}
      handleChange={handleChange}
      handleSelectAll={handleSelectAll}
      handleSelectNone={handleSelectNone}
    />
  );
};
