import { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { Account, Attachment, InventoryAsset, SupportTicketsItem, User } from '../../../types';
import { Icon } from '../../Atoms/Icon';
import { AttachmentQueue } from '../AttachmentQueue';
import { ServiceSelectionForm } from '../TicketCreation/ServiceSelectionForm';
import { TicketCreatedForm } from '../TicketCreation/TicketCreatedForm';
import { FormData, TicketForm } from '../TicketCreation/TicketForm';
import { Wizard, WizardConfiguration } from './Wizard';
import { useAttachments } from '../../../Api/Attachments/useAttachments';
import { CaseRequest } from '../../../Api/Tickets/postTicket';
import { addTicket } from '../../../store/SupportTicket';
import { getAccountScope, getUserSubject } from '../../../store/User';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { combineLatest } from 'rxjs';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { AttachmentState, getSubject, useAttachmentStore } from '../../../store/Attachment';
import { TextSpan } from '../../Atoms/Text';
import { PostSharedAccountCustomizationRequest } from '../../../Api/Preferences/SharedAccountCustomizations/sharedAccountCustomizationsApi';
import {
  SharedAccountCustomizationData,
  createSharedAccountCustomizations,
  defaultSharedAccountCustomizationData,
  fetchSharedAccountCustomizations,
  getSharedAccountCustomizationsLoadingSubject,
  getSharedAccountCustomizationsSubject,
  updateSharedAccountCustomizations
} from '../../../store/SharedAccountCustomizations';
import { fetchAggregateInventory } from '../../../Api/Inventory/aggregateInventoryApi';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { DisabledSupportTicketButton } from '../DisabledSupportTicketButton';

const contentStyle = {
  borderRadius: '10px',
  border: '2px solid #F7F7F7',
  width: '400px',
  padding: '0.5rem 1rem',
  minHeight: '400px'
};

export interface SupportTicketWizardProps {
  ctaClassName: string;
  onFormSubmissionHandler?: () => Promise<void>;
  onFormCloseHandler?: () => Promise<void>;
  inventoryAsset?: InventoryAsset;
  disabled?: boolean;
  disabledMessage?: string;
}

export function SupportTicketWizard({
  ctaClassName,
  onFormSubmissionHandler,
  onFormCloseHandler,
  inventoryAsset,
  disabled = false,
  disabledMessage = ''
}: SupportTicketWizardProps) {
  const [open, setOpen] = useState(false);
  const [configurations, setConfigurations] = useState<WizardConfiguration[]>([]);
  const [service, setService] = useState<InventoryAsset | null>(inventoryAsset || null);
  const [ticketPayload, setTicketPayload] = useState<CaseRequest | null>(null);
  const [ticket, setTicket] = useState<SupportTicketsItem | null>(null);
  const [ticketFormFilledOut, setTicketFormFilledOut] = useState<boolean>(false);
  const [ticketFormSubmitted, setTicketFormSubmitted] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [inventory, setInventory] = useState<InventoryAsset[] | undefined>(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [queuedAttachments, setQueuedAttachments] = useState<Attachment[]>([]);
  const [additionalRecipients, setAdditionalRecipients] = useState<string[]>([]);
  const [saveDefault, setSaveDefault] = useState<boolean>(false);
  const [acctCust, setAcctCust] = useState<SharedAccountCustomizationData>();
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);

  const { setSnack } = useSnackbar();
  const { clear } = useAttachmentStore();
  const { uploadAttachments, cancelUpload, retryUpload, setParentId } = useAttachments();

  useEffect(() => {
    if (inventoryAsset) {
      setService(inventoryAsset);
      setConfigurations(twoStepConfiguration);
    } else {
      setConfigurations(threeStepConfiguration);
    }

    fetchSharedAccountCustomizations();
    getAssets();
    const userSub = getUserSubject().subscribe((userData) => setUser(userData));
    const attachmentSubscription = getSubject().subscribe(({ queuedAttachments }: AttachmentState) => {
      setQueuedAttachments(queuedAttachments);
    });

    const loadingSubscription = combineLatest([
      getInventoryLoadingSubject(),
      getSharedAccountCustomizationsLoadingSubject()
    ]).subscribe((data) => setDataLoading(data.some((i) => i)));

    const sharedAccountCustomizationsSub = getSharedAccountCustomizationsSubject().subscribe((ac) => {
      if (ac) {
        const currentAcctCust: SharedAccountCustomizationData = JSON.parse(
          JSON.stringify(
            ac.find((i) => i?.account_id === selectedAccount?.id) ||
              defaultSharedAccountCustomizationData(selectedAccount?.id, selectedAccount?.name)
          )
        );
        setAcctCust(currentAcctCust);

        const defaultRecip = currentAcctCust?.content?.additionalRecipients || [];
        setAdditionalRecipients(defaultRecip);
      }
    });

    const featureFlagSub = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    return () => {
      if (attachmentSubscription) attachmentSubscription.unsubscribe();
      if (userSub) userSub.unsubscribe();
      if (sharedAccountCustomizationsSub) sharedAccountCustomizationsSub.unsubscribe();
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (featureFlagSub) featureFlagSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (ticket?.sfId) setParentId(ticket.sfId);
  }, [ticket?.sfId]);

  useEffect(() => {
    getAssets();
  }, [selectedAccount?.id]);

  const getAssets = async () => {
    if (selectedAccount) {
      setDataLoading(true);
      const { data, error } = await fetchAggregateInventory([selectedAccount.id]);
      if (data) {
        const inventoryAssets: InventoryAsset[] = (data as InventoryAsset[])
          .map((i) => {
            const isUpstackManaged = !i.outside_inventory_schema_id;
            i['UPSTACK Managed'] = isUpstackManaged;
            return i;
          })
          .filter((asset) => asset['UPSTACK Managed']);
        setInventory(inventoryAssets);
        if (!inventoryAssets.length) {
          setSnack({ message: 'No services found for selected account.', type: 'warning', open: true });
        }
      }
      if (error) {
        setSnack({ message: 'There was an error retrieving assets.', type: 'error', open: true });
      }
      setDataLoading(false);
    }
  };

  const handleSelectInventory = (selectedService: InventoryAsset | null): void => {
    setService(selectedService);
  };

  const handleValidateTicketForm = (formState: FormState<FormData>) => {
    const { isValid } = formState;

    setTicketFormFilledOut(isValid);
  };

  const handleOpenForm = () => {
    setSelectedAccount(getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined);
    setOpen(true);
  };

  const resetWizard = () => {
    setService(inventoryAsset || null);
    setTicketPayload(null);
    setInventory(undefined);
    setTicket(null);
    setTicketFormFilledOut(false);
    setTicketFormSubmitted(false);
    setAcctCust(undefined);
    setSaveDefault(false);
    setAdditionalRecipients([]);
    setSelectedAccount(undefined);
    clear();
  };

  const closeWizard = async () => {
    setOpen(false);
    resetWizard();

    if (ticketFormSubmitted && onFormSubmissionHandler) {
      await onFormSubmissionHandler();
    }
    if (onFormCloseHandler) {
      await onFormCloseHandler();
    }
  };

  const saveAccountCust = async (currentAcctCust: SharedAccountCustomizationData) => {
    if (selectedAccount?.id) {
      const req: PostSharedAccountCustomizationRequest = {
        account_id: selectedAccount?.id || '',
        account_name: selectedAccount?.name || '',
        content: currentAcctCust.content
      };

      const res = currentAcctCust?.id
        ? await updateSharedAccountCustomizations(currentAcctCust?.id, req)
        : await createSharedAccountCustomizations(req);

      if (res) {
        setSnack({
          message: 'Settings Saved',
          open: true,
          type: 'success'
        });
      }
    }
  };

  const handleSubmitTicketForm = (formData: FormData): void => {
    const issueType =
      formData?.isServiceDown === 'true' || formData?.serviceSubject === 'Other' ? 'General Support' : 'Customer Care';

    const requestBody: CaseRequest = {
      Status: 'New',
      AccountId: selectedAccount?.sfId,
      ContactId: user?.id,
      Description: formData?.Description,
      Type: issueType,
      RecordTypeId: '0125G000000bKmIQAU',
      Reason: formData?.Reason,
      Priority: formData?.Priority,
      OrderProductId: service?.sfId || '',
      AdditionalRecipients: additionalRecipients.join(', '),
      supplierTicketNumber: formData?.supplierTicketNumber
    };
    setTicketPayload(requestBody);
    setTicketFormSubmitted(true);

    if (saveDefault && additionalRecipients.length > 0) {
      const currentAcctCust = { ...acctCust } as SharedAccountCustomizationData;
      if (currentAcctCust?.content) {
        currentAcctCust.content.additionalRecipients = additionalRecipients;
      }
      saveAccountCust(currentAcctCust);
    }
  };

  async function handlePostTicket() {
    if (ticketPayload) {
      const newTicket = await addTicket(ticketPayload);
      if (newTicket) {
        setSnack({ message: 'Your support ticket has been created successfully', type: 'success', open: true });
        setTicket(newTicket);
      } else {
        setSnack({ message: 'There was an error sending your request.', type: 'error', open: true });
        setTicketFormSubmitted(false);
      }
    }
  }

  const handleSetConfigurations = () => {
    setConfigurations(inventoryAsset ? twoStepConfiguration : threeStepConfiguration);
  };

  const threeStepConfiguration: WizardConfiguration[] = [
    {
      step: 1,
      childComponent: 0,
      headerCopy: 'Submit a Ticket',
      progressBarCopy: 'Select a Service',
      prevStep: null,
      nextStep: 2,
      showExitBtn: true,
      exitBtnCopy: 'Cancel',
      canMoveNextStep: service !== null,
      canMovePrevStep: false,
      handleExitWizard: () => closeWizard()
    },
    {
      step: 2,
      childComponent: 1,
      headerCopy: 'Submit a Ticket',
      progressBarCopy: 'Ticket Details',
      nextBtnCopy: 'Submit Ticket',
      nextBtnForm: 'ticket-creation-form',
      submitForm: ticketFormSubmitted ? handlePostTicket : null,
      formSubmissionComplete: ticket !== null,
      prevStep: 1,
      nextStep: 3,
      showExitBtn: true,
      exitBtnCopy: 'Cancel',
      canMoveNextStep: ticketFormFilledOut,
      canMovePrevStep: true,
      handleExitWizard: () => closeWizard()
    },
    {
      step: 3,
      childComponent: 2,
      headerCopy: 'Ticket Created',
      progressBarCopy: 'Attachments',
      nextBtnCopy: 'Done',
      prevStep: 2,
      nextStep: null,
      blockPrevStep: true,
      canMoveNextStep: true,
      canMovePrevStep: false,
      handleExitWizard: () => closeWizard()
    }
  ];

  const twoStepConfiguration: WizardConfiguration[] = [
    {
      step: 1,
      childComponent: 1,
      headerCopy: 'Submit a Ticket',
      progressBarCopy: 'Ticket Details',
      nextBtnCopy: 'Submit Ticket',
      nextBtnForm: 'ticket-creation-form',
      submitForm: ticketFormSubmitted ? handlePostTicket : null,
      formSubmissionComplete: ticket !== null,
      prevStep: null,
      nextStep: 2,
      showExitBtn: true,
      exitBtnCopy: 'Cancel',
      canMoveNextStep: ticketFormFilledOut,
      canMovePrevStep: true,
      handleExitWizard: () => closeWizard()
    },
    {
      step: 2,
      childComponent: 2,
      headerCopy: 'Ticket Created',
      progressBarCopy: 'Attachments',
      nextBtnCopy: 'Done',
      prevStep: 1,
      nextStep: null,
      blockPrevStep: true,
      canMoveNextStep: true,
      canMovePrevStep: false,
      handleExitWizard: () => closeWizard()
    }
  ];

  useEffect(() => {
    setService(inventoryAsset || null);
  }, [inventoryAsset]);

  useEffect(() => {
    handleSetConfigurations();
  }, [service, ticket, ticketFormFilledOut, ticketFormSubmitted]);

  if (featureFlags.disable_support_tickets) return <DisabledSupportTicketButton ctaClassName={ctaClassName} />;

  let content;

  if (disabled) {
    content = (
      <Popup
        className="custom-tooltip"
        trigger={
          <div className="flex items-center space-x-2">
            <Icon type="plusDisabled" />
            <button
              className={`${ctaClassName} text-slate-400`}
              data-cy="new-ticket"
              onClick={() => handleOpenForm()}
              disabled={true}>
              New Ticket
            </button>
          </div>
        }
        on="hover"
        position="bottom right">
        <p>{disabledMessage}</p>
      </Popup>
    );
  } else {
    content = (
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className={`${ctaClassName} ${disabled ? 'text-slate-400' : ''}`}
          data-cy="new-ticket"
          onClick={() => handleOpenForm()}
          disabled={disabled}>
          New Ticket
        </button>
        <Popup
          closeOnDocumentClick={false}
          open={open}
          modal={true}
          contentStyle={contentStyle}>
          <Wizard
            configurations={configurations}
            dataLoading={dataLoading}>
            <ServiceSelectionForm
              onSubmit={handleSelectInventory}
              services={inventory || []}
              selectedService={service}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
            />
            <div>
              {service && (
                <TicketForm
                  service={service}
                  onSubmit={handleSubmitTicketForm}
                  onChange={handleValidateTicketForm}
                  additionalRecipients={additionalRecipients}
                  setAdditionalRecipients={setAdditionalRecipients}
                  saveDefault={saveDefault}
                  setSaveDefault={(_e, v) => setSaveDefault(v)}
                />
              )}
            </div>
            <div>
              {ticket && (
                <TicketCreatedForm
                  addAttachments={uploadAttachments}
                  data={ticket}
                  headerCopy={`Ticket #${ticket.caseNumber}`}
                  bodyCopy="Your ticket has been sent to UPSTACK successfully. You can now optionally upload relevant attachments to your ticket"
                  ctaCopy="Add Attachment"
                />
              )}
              {queuedAttachments.length > 0 && (
                <>
                  <hr className="mt-5 mb-5" />
                  <AttachmentQueue
                    queuedAttachments={queuedAttachments}
                    cancelUpload={cancelUpload}
                    retryUpload={retryUpload}
                  />
                </>
              )}
            </div>
          </Wizard>
        </Popup>
      </TextSpan>
    );
  }
  return content;
}
