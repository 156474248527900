import { GridFilterItem, GridSingleSelectColDef } from '@mui/x-data-grid';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';
import { UserPreferencesContent } from '../../../store/User';
import { v4 } from 'uuid';

export type CategorizedData<InventoryAsset> = Record<ProductCategories, InventoryAsset[]>;

export enum ProductCategories {
  ALL = 'All',
  COLOCATION = 'Colocation',
  COMMUNICATIONS = 'Communications',
  NETWORK = 'Network',
  SOFTWARE_DEFINED_NETWORKING = 'Software Defined Networking',
  OTHER = 'Other',
  BUSINESS_CONTINUITY = 'Business Continuity',
  CPE = 'CPE',
  INFRASTRUCTURE = 'Infrastructure',
  IOT = 'IoT',
  MANAGED_SERVICES = 'Managed Services',
  MOBILITY = 'Mobility',
  PROFESSIONAL_SERVICES = 'Professional Services',
  SECURITY = 'Security'
}

export const TABS_LIST = [
  'Supplier',
  'Product',
  'Term Length',
  'Monthly Cost',
  'Status',
  'Install Date',
  'Est. Contract End Date',
  'Account Number',
  'UPSTACK Sourced'
];

export const netOrSDN = ['Address', 'Circuit ID', ...TABS_LIST];
export const colocation = ['Address', 'kW Rack', ...TABS_LIST];
export const communications = ['Address', ...TABS_LIST];
export const allColumns = (DefaultInventoryGridColumnDefs || []).map((item) => item.headerName) as string[];

export const currentTabData = (name: string): string[] => {
  switch (name) {
    case ProductCategories.ALL:
      return allColumns.filter((item) => item !== 'Details');
    case ProductCategories.COLOCATION:
      return colocation;
    case ProductCategories.COMMUNICATIONS:
      return communications;
    case ProductCategories.NETWORK:
    case ProductCategories.SOFTWARE_DEFINED_NETWORKING:
      return netOrSDN;
    case ProductCategories.OTHER:
    case ProductCategories.BUSINESS_CONTINUITY:
    case ProductCategories.CPE:
    case ProductCategories.INFRASTRUCTURE:
    case ProductCategories.IOT:
    case ProductCategories.MANAGED_SERVICES:
    case ProductCategories.MOBILITY:
    case ProductCategories.PROFESSIONAL_SERVICES:
    case ProductCategories.SECURITY:
      return TABS_LIST;
    default:
      return allColumns.filter((item) => item !== 'Details');
  }
};

export const getColumnsByProduct = (tab: string): GridSingleSelectColDef[] => {
  const productColumns = ['Details', ...currentTabData(tab)];
  return productColumns.reduce((gridDefs: GridSingleSelectColDef[], col: string) => {
    const matchedDef = DefaultInventoryGridColumnDefs.find((def) => def.headerName === col);
    if (matchedDef) gridDefs.push(matchedDef);
    return gridDefs;
  }, []);
};

export const getTabData = <T>(data: T[]) => {
  const categorizedData: CategorizedData<T> = {
    ...data.reduce(
      (acc: Record<ProductCategories, T[]>, cur) => {
        const product = cur as unknown as { product: { family: string } };
        const family = product.product.family;
        const category = !family || family == 'None' ? ProductCategories.OTHER : (family as ProductCategories);

        if (category in acc) {
          acc[category].push(cur);
        } else {
          acc[category] = [cur];
        }

        return acc;
      },
      { [ProductCategories.ALL]: data } as Record<ProductCategories, T[]>
    )
  };

  const tabs = [
    ...Object.entries(categorizedData)
      .filter(([key, values]) => values.length > 0 && key.length > 0)
      .map(([category, values]) => ({
        name: category as string,
        count: values.length
      }))
  ];

  return { tabs, categorizedData };
};

export const contentWithProductFilters = (
  content: UserPreferencesContent,
  tabs: string[],
  products: string[]
): UserPreferencesContent => {
  const updatedContent = content;
  tabs.forEach((tab) => {
    const tabContent = updatedContent[`inventory_grid_${tab}`] || {
      muiConfig: { filter: { filterModel: { items: [] } } },
      disabledFields: []
    };
    if (tab !== 'All') {
      const filter: GridFilterItem = {
        id: v4(),
        field: 'productDisplay',
        operator: 'isAnyOf',
        value: products.filter((p) => p.startsWith(tab))
      };
      if (tabContent.muiConfig?.filter?.filterModel) {
        tabContent.muiConfig.filter.filterModel.items = tabContent.muiConfig.filter.filterModel.items.filter(
          (f) => f.field !== 'productDisplay'
        );
        tabContent.muiConfig.filter.filterModel.items.push(filter);
      }
    }
    updatedContent[`inventory_grid_${tab}`] = tabContent;
  });
  return updatedContent;
};
